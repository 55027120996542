<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2024-09-18 10:07 -->
<!-- Description:  -->
<template>
  <div>
    <VueSimpleSuggest
      :list="names" :filter-by-query="true"
      display-attribute="tratamiento"
      @input="valueChild = $event">
      <input class="form-control"
        style="border: none; border-color: transparent;" type="text"
        @focus="getListFirstTime()"
      />
    </VueSimpleSuggest>
  </div>
</template>
<script>
import StoreService from "src/lt/store-module/StoreService.js";
import VueSimpleSuggest from 'vue-simple-suggest';
// script content
export default {
  name: "InputSuggest",
  components: {
    VueSimpleSuggest
  },

  // directives

  props: {
    value: { required: true }
  },

  data: () => ({
    list: [],
    names: [],
  }),

  computed: {
    valueChild: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },

  // watch: {},

  mounted() {
    //
  },

  methods: {
    async getListFirstTime() {
      if (this.list.length == 0) {
        let res = await StoreService.getProducts({
          per_page: 9999
        });
        this.list = res.data;
        this.names = res.data.map((x) => {
          return x.name;
        });
      }
    }
    //
    //
  }
};
</script>
<style scoped>
</style>

