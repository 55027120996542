<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2024-09-18 09:49 -->
<!-- Description:  -->
<template>
  <div>
    <table class="table table-sm table-bordered">
      <thead>
        <tr>
          <th>Descripcion</th>
          <th>Pieza</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in list" :key="i">
          <td class="p-0">
            <ProductInputSuggest
              v-model="item.product_name"
            ></ProductInputSuggest>
          </td>
          <td class="p-0 col-3">
            <input
              type="text"
              class="form-control"
              style="border: none; border-color: transparent;"
              v-model="item.piece"
            />
          </td>
          <td class="col-1">
            <button class="btn btn-sm btn-danger" @click="remoteItem(i)"><i class="fas fa-times"></i></button>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <button class="btn btn-sm btn-success btn-block" @click="addItem()">Agregar</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import ProductInputSuggest from "../products/ProductInputSuggest.vue"
// script content
export default {
  name: "QuoteFormTreatmentsTable",
  components: {
    ProductInputSuggest,
  },

  // directives

  props: {
    //
  },

  data: () => ({
    list: [{}],
  }),

  computed: {
    //
  },

  // watch: {},

  mounted() {
    //
  },

  methods: {
    reset() {
      this.list = [{}];
    },
    addItem() {
      this.list.push({});
    },
    remoteItem(i) {
      this.list.splice(i, 1);
    },
    getValues() {
      // return copy
      return JSON.parse(JSON.stringify(this.list));
    }
    //
    //
  }
};
</script>
<style scoped></style>

